import React from "react";
import "../css/projects.css";
import Footer from "../components/Footer";
import ProjectLogoImage from "../components/ProjectLogoImage";

export default function Projects() {
  return (
    <div className="projects">
      <br />
      <br />

      <h3 className="section-headers">Languages I'm Learning/Working With</h3>
      <div className="language-icons">
        <img
          src="images/languages/lang-react.png"
          alt="React Programming Language"
        />
        <img
          src="images/languages/lang-js.png"
          alt="JavaScript Programming Language"
        />
        <img src="images/languages/lang-html.png" alt="html" />
        <img src="images/languages/lang-css.png" alt="css" />
        <img src="images/languages/lang-android.png" alt="android java" />
        <img
          src="images/languages/lang-swift.png"
          alt="Swift Programming Language"
        />
      </div>
      <br />

      {/* CURRENT PROJECTS */}
      <h2 className="section-headers">Currently Working On</h2>
      <div className="general-projects-container" id="projects-container">
        <p className="intro-text">Project Details Coming Soon</p>
        <div className="projects-scroll" id="projects-scroll">
          <ProjectLogoImage
            picture="asusu/asusu-app-logo"
            projectName="Asusu Igbo Language App"
            projectType="Web + Mobile"
          />
          <ProjectLogoImage
            picture="asusu/asusu-web-logo"
            projectName="Asusu Igbo Language Website"
            projectType="Web + Mobile"
          />
          <ProjectLogoImage
            picture="odara/odara-logo"
            projectName="Odara Health App"
            projectType="Mobile"
          />
        </div>
      </div>
      <br />

      {/* PREVIOUS PROJECTS */}
      <h2 className="section-headers">Previous Projects</h2>
      <div className="general-projects-container" id="projects-container">
        <p className="intro-text">Project Details Coming Soon</p>
        <div className="projects-scroll" id="projects-scroll">
          <ProjectLogoImage
            picture="somkene/somkene"
            projectName="My Personal Website"
            projectType="Mockups"
          />
        </div>
      </div>
      <br />

      {/* EVENTS */}
      <h2 className="section-headers">Events</h2>
      <div className="general-projects-container" id="projects-container">
        <p className="intro-text">Project Details Coming Soon</p>
        <div className="projects-scroll" id="projects-scroll">
          <ProjectLogoImage
            picture="hacklboro/hack-lboro"
            projectName="HackLboro: Hackathon"
            projectType="Case Study"
          />
        </div>
      </div>

      <br />

      <Footer />
    </div>
  );
}
