import React from "react";
import "../css/projects.css";
import Footer from "../components/Footer";
import ProjectLogoImage from "../components/ProjectLogoImage";

export default function SingleProject() {
  return (
    <div className="projects">
      <br />
      {/* CURRENT PROJECTS */}
      <h2 className="section-headers">Currently Working On</h2>
      <div className="general-projects-container" id="projects-container">
        <ProjectLogoImage
          picture="asusu/asusu-app-logo"
          projectName="Asusu Igbo Language App"
          projectType="Web + Mobile"
        />
      </div>
      <br />

      <Footer />
    </div>
  );
}
