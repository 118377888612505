import React from "react";
import "../css/project-logo-image.css";

const ProjectLogoImage = ({ picture, projectName, projectType }) => {
  return (
    <div className="individual-project">
      <img src={"/images/" + picture + ".png"} alt="" />
      <br />
      <h3 className="project-name">{projectName}</h3>
    </div>
  );
};

export default ProjectLogoImage;
