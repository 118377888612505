import React, { useEffect, useState } from "react";
import { NavLink, withRouter } from "react-router-dom";
import "../css/header-footer.css";
import CV from "../files/Som_E-Agwuegbo_CV.pdf";

const Header = ({ location }) => {
  const [menuClosed, setMenuClosed] = useState(true);

  useEffect(() => {
    setMenuClosed(true);
  }, [location]);

  return (
    <header>
      <div className="logo-container" id="logo-container">
        {/* <!-- desktop logo width > 875px --> */}
        <img
          src="/images/logos/header-logo-desktop.svg"
          alt="somkene.co.uk logo"
          className="logo hide-mobile show-desktop"
        />
        {/* <!-- mobile logo width < 875px + side menu icon --> */}
        <img
          src="/images/logos/header-logo-mobile.svg"
          alt="somkene.co.uk logo"
          className="logo hide-desktop"
        />
        {/* mobile navigation menu icon */}
        <img
          src="/images/menu/hamburger-icon.svg"
          alt=""
          className="menu-icon hide-desktop"
          id="menu"
          onClick={() => setMenuClosed(false)}
        />
        <p className="signature-font">By Som E-Agwuegbo</p>
      </div>
      {/* <!-- navbar links --> */}
      <nav id="nav-container">
        <ul
          id="nav"
          className={
            menuClosed
              ? " show-desktop hide-mobile"
              : "show-desktop show-mobile"
          }
        >
          <li id="exit" className="exit-btn hide-desktop">
            <img
              src="/images/menu/exit-black.svg"
              alt="exit menu icon"
              onClick={() => setMenuClosed(true)}
            />
          </li>
          <li>
            <NavLink exact to="/" activeClassName="active">
              Home
            </NavLink>
          </li>
          <li>
            <NavLink exact to="/projects" activeClassName="active">
              Projects
            </NavLink>
          </li>
          <li>
            <a href={CV} target="_blank" rel="noopener noreferrer">
              My CV!
            </a>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default withRouter(Header);
