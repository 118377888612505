import React from "react";
import "../css/header-footer.css";

const Footer = () => {
  return (
    <footer>
      <div className="socials-info">
        <h2>Follow Me On</h2>
        <ul>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/somkene.dev/"
            >
              @somkene.dev
            </a>
          </li>
          <li>
            <img
              src="/images/socials/instagram-icon.svg"
              alt="instagram icon"
            />
          </li>
        </ul>
        <ul>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.linkedin.com/in/someagwuegbo/"
            >
              Som E-Agwuegbo
            </a>
          </li>
          <li>
            <img src="/images/socials/linkedin-icon.svg" alt="linkedin icon" />
          </li>
        </ul>
      </div>
      <div className="footer-logo">
        <img
          src="/images/logos/footer-logo.svg"
          alt="somkene.co.uk black logo"
        />
      </div>
    </footer>
  );
};

export default Footer;
