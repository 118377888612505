import React from "react";
import Header from "./components/Header";
import { Route, Switch, withRouter } from "react-router";
import Projects from "./pages/Projects";
import Home from "./pages/Home";
import SingleProject from "./pages/SingleProject";

function App() {
  return (
    <div className="App">
      <Header />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/projects/:id" component={SingleProject} />
        <Route exact path="/projects" component={Projects} />
      </Switch>
    </div>
  );
}

export default withRouter(App);
