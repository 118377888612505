import React from "react";
import "../css/home.css";
import Footer from "../components/Footer";
import ProjectLogoImage from "../components/ProjectLogoImage";

export default function Home() {
  return (
    <>
      <div className="landing">
        <img
          src="images/my-face.png"
          alt="Som E-Agwuegbo Face"
          className="my-face"
        />
        <p className="intro-text">
          I'm a third year computer science student, interested in learning Web
          + Mobile Development and UI Design
        </p>
      </div>

      <div className="latest-projects-container" id="projects-container">
        <h1>Latest Projects</h1>
        <p className="intro-text">Project Details Coming Soon</p>
        <div className="projects-scroll" id="home-projects-scroll">
          <ProjectLogoImage
            picture="somkene/somkene"
            projectName="My Personal Website"
            projectType="Mockups"
          />
          {/* <ProjectLogoImage
            picture="hacklboro/hack-lboro"
            projectName="HackLboro: Hackathon"
            projectType="Case Study"
          />
          <ProjectLogoImage
            picture="asusu/asusu-app-logo"
            projectName="Asusu Igbo Language App"
            projectType="Mobile"
          /> */}
          <ProjectLogoImage
            picture="asusu/asusu-web-logo"
            projectName="Asusu Igbo Language Website"
            projectType="Web"
          />
          <ProjectLogoImage
            picture="odara/odara-logo"
            projectName="Odara Health App"
            projectType="Mobile"
          />
        </div>
      </div>
      <Footer />
    </>
  );
}
